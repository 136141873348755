import {SlideNumber, initializeSliderOnView} from "../sliders/slide-func.js";

/* ---------------- SLIDER Z FILMIKAMI SHORTS YOUTUBE --------------------------------------- */
document.addEventListener("DOMContentLoaded", function () {

    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    const shortsCount=$(document).find('.s-shortsYtSliderPopUp');

    $(shortsCount).each(function( index ) {
     const uniqueId=this.id;
    const shortsYtSlider = document.getElementById(uniqueId); // section z sliderem
    const shortsList = document.getElementById('shorts_list_'+uniqueId); // lista z filmikami
    const images = shortsList.querySelectorAll('img'); // img prewiew każdego filmiku
    const nextButton = document.getElementById("nextButton_"+uniqueId); // przycisk następnego filmiku
    const prevButton = document.getElementById("prevButton_"+uniqueId); // przycisk poprzedniego filmiku
    const closeShortBtn = document.getElementById("closeShortBtn_"+uniqueId); // przycisk zamykania filmiku
    const imageIds = Array.from(images).map(img => img.id); // img prewiew pojedynczego filmiku
    const playerContainer = document.getElementById("player_"+uniqueId); // tworzy kontener dla iframe z filmikiem
    let shortsYoutubeWidth; // wysokosć filmiku - zmienia się w załeżności od szerokości okna
    let shortsYoutubeHeight; // szerokosć filmiku - zmienia się w załeżności od szerokości okna

    // Zmienia szerokosc i wysokosć filmiku w zależności od szerokosci mobilki / desctop
    $(window).on('load', function () {
        if ($(window).width() < 376) {
            shortsYoutubeWidth = 310;
            shortsYoutubeHeight = 480;
        } else if ($(window).width() > 377 && $(window).width() < 991) {
            shortsYoutubeWidth = 318;
            shortsYoutubeHeight = 564;
        } else if ($(window).width() > 992 && $(window).width() < 1599) {
            shortsYoutubeWidth = 304;
            shortsYoutubeHeight = 520;
        } else {
            shortsYoutubeWidth = 380;
            shortsYoutubeHeight = 672;
        }
    });

    // Inicializacja slidera z youtube shorts
    $(this).find("[data-shorts-slider-popup]").each(function () {
        var sliderId = $(this).attr("id");

        var splideConfig = {
            perPage: 8,
            perMove: 8,
            pagination: true,
            arrows: true,
            interval: 6000,
            gap: 16,
            breakpoints: {
                1740: {
                    perPage: 7,
                    perMove: 7,
                    gap: 10,
                },
                1366: {
                    perPage: 6,
                    perMove: 6,
                },
                1300: {
                    perPage: 5,
                    perMove: 5,
                },
                991: {
                    perPage: 4,
                    perMove: 4,
                },
                767: {
                    perPage: 3,
                    perMove: 3,
                    pagination: false,
                },
                479: {
                    perPage: 2,
                    perMove: 2,
                    gap: 4,
                    pagination: false,
                },
            },
        };

        initializeSliderOnView('.s-shortsYtSliderPopUp', `#${sliderId}`, splideConfig, SlideNumber);
    });

    // listener kliknięcia na pojedynczy filmik
    shortsList.addEventListener('click', (event) => {
        const clickedElement = event.target;

        /* if (clickedElement.tagName === 'IMG') { */
        if (clickedElement.tagName === 'BUTTON') {
            const shortId = clickedElement.id;
            youtubeApi(shortId);
        }
    });


    // pobiera filmiki z API
    function youtubeApi(shortId) {
        var player;
        let currentVideoIndex;
        const newPlayerContainer = document.createElement("div");
        const playerId = "player-" + shortId;
        const modal = document.getElementById("shortsModal_"+uniqueId);

        newPlayerContainer.id = playerId;
        playerContainer.appendChild(newPlayerContainer);

        player = new YT.Player(playerId, {
            height: shortsYoutubeHeight,
            width: shortsYoutubeWidth,
            videoId: shortId,
            events: {
                'onReady': onPlayerReady
            },
            playerVars: {
                'mute': 1
            }
        });

        function onPlayerReady(event) {
            event.target.playVideo();
        }

        shortsYtSlider.classList.add("contentVisibility");      // class visibility - nie usuwać
        modal.classList.add("playShort");                       // class visibility - nie usuwać
        modal.setAttribute("tabindex", "0"); // wcag atrybut - nie usuwać

        const totalVideos = imageIds.length;
        currentVideoIndex = imageIds.indexOf(shortId);

        // Obsługa obu przycisków
        function handleButtonClick(direction) {
            if (player) {
                currentVideoIndex += direction; // 1 dla "następny", -1 dla "poprzedni"
                currentVideoIndex = Math.max(0, Math.min(currentVideoIndex, totalVideos - 1));

                shortId = imageIds[currentVideoIndex];
                player.loadVideoById(shortId);

                updateButtonVisibility();
            }
        }

        nextButton.onclick = () => handleButtonClick(1);
        prevButton.onclick = () => handleButtonClick(-1);

        updateButtonVisibility();

        function updateButtonVisibility() {
            prevButton.style.display = currentVideoIndex === 0 ? "none" : "flex";
            nextButton.style.display = currentVideoIndex === totalVideos - 1 ? "none" : "flex";
        }

        // zamyka filmik po kliknięciu na przycisk X
        closeShortBtn.onclick = function (event) {
            shortsYtSlider.classList.remove("contentVisibility");
            modal.classList.remove("playShort");
            modal.removeAttribute("tabindex"); // wcag atrybut - nie usuwać
            player.stopVideo();
            const iframeToRemove = document.getElementById(playerId);
            iframeToRemove.remove();
        }
    }
});
});